import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/projects";
const STORE_NAME = "projects";

export const fetchAndStoreProjects = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        // Clear old data and store new data
        await db.clearStore(STORE_NAME);
        data.forEach((item) => db.addItem(STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(STORE_NAME);
  }
};

export const getProject = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(STORE_NAME, id);
  }
};

export const addProject = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreProjects();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to create ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to create ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "addProject", STORE_NAME, item });
    await db.addItem(STORE_NAME, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const editProject = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/edit`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreProjects();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "editProject", STORE_NAME, item });
    await db.editItem(STORE_NAME, item.uuid, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const deleteProject = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreProjects();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "deleteProject", STORE_NAME, item });
    await db.deleteItem(STORE_NAME, item.uuid);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const syncOfflineProjectsChanges = async () => {
  if (!navigator.onLine) return;

  const offlineChanges = await db.getOfflineChanges();
  for (const change of offlineChanges) {
    store.commit("startSync", true);
    try {
      if (change.type === "addProject") {
        await addProject(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "editProject") {
        await editProject(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "deleteProject") {
        await deleteProject(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      store.dispatch("endSync");
    } catch (error) {
      console.error(`Failed to sync change for ${change.STORE_NAME}:`, error);
    }
  }
};
